// Gatsby SSR API

// En AX siempre cargamos todas las clases de Grisso para tener acceso a todas
// las clases de Grisso.
const grissoDevMode = (
	<link rel="stylesheet" href="/grisso.css" as="style" id="grisso" />
);

const griddoWebfonts = (
	<link
		rel="preload"
		href="/webfonts.css"
		as="style"
		// This id is required in order to usen it later in the `builder.browser.js`
		id="griddo-webfonts"
		crossOrigin
		// This onload only works on Griddo Editor (AX)
		// The code to trigger the onload for the build phase (CX) is th builder.browser.js
		onLoad="this.onload=null;this.rel='stylesheet'"
	/>
);

const preconnectGTM = (
	<link rel="preconnect" href="//www.googletagmanager.com" />
);

const prefetchGTM = (
	<link rel="dns-prefetch" href="https://www.googletagmanager.com" />
);

const preconnectDAM = (
	<link rel="preconnect" href="https://images.griddo.comillas.edu/" />
);

const prefetchDAM = (
	<link rel="dns-prefetch" href="https://images.griddo.comillas.edu/" />
);

const preconnectAssets = (
	<link rel="preconnect" href="https://assets.griddo.comillas.edu/" />
);

const prefetchAssets = (
	<link rel="dns-prefetch" href="https://assets.griddo.comillas.edu/" />
);

// onRenderBody hook
function onRenderBody({ setHeadComponents, setBodyAttributes, pathname }) {
	// common headComponents
	const commonHeadComponents = [griddoWebfonts];

	// Set different scripts for Griddo Builder and Griddo Editor.
	const headComponents =
		pathname === "ax-editor"
			? [...commonHeadComponents, grissoDevMode]
			: [
					preconnectGTM,
					prefetchGTM,
					preconnectDAM,
					prefetchDAM,
					preconnectAssets,
					prefetchAssets,
					...commonHeadComponents,
			  ];

	setHeadComponents(headComponents);
	setBodyAttributes({ id: "griddo-site" });
}

export default {
	onRenderBody,
};
